<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/sys/log">日志管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">      
      <el-form-item label="起始时间" prop="startTime">
        <el-date-picker
          v-model="queryModel.startTime"
          type="datetime" size="mini" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
          placeholder="选择日期时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="endTime">
        <el-date-picker
          v-model="queryModel.endTime"
          type="datetime" size="mini" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
          placeholder="选择日期时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="用户编号" prop="userId">
        <el-input type="text" size="mini" v-model="queryModel.userId"></el-input>
      </el-form-item>
      <el-form-item label="入口" prop="pointcut">
        <el-input type="text" size="mini" v-model="queryModel.pointcut"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="text" size="mini" v-model="queryModel.remark"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      border
    >
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <el-form label-position="left" inline>
            <el-form-item label="参数:">
              <span>{{ row.data }}</span>
            </el-form-item>
            <el-form-item label="远程IP:">
              <span>{{ row.remoteIp }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        prop="pointcut"
        label="入口"
        width="250"
      ></el-table-column>
      <el-table-column
        prop="userId"
        label="用户编号"
        width="180"
      ></el-table-column>
      <el-table-column 
        prop="remark"
        label="备注">
        <template slot-scope="{row}">
            <span>{{ row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column 
        prop="elapse"
        label="耗时(毫秒)">
        <template slot-scope="{row}">
            <span>{{ row.elapse }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        sort-by="create_time"
        label="创建时间"
        width="180"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Constant from "@/constant";
import logApi from "@/api/sys/log";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name:'SysLogList',
  data() {
    var self = this;
    var now = new Date();

    var dateStr = [];

    dateStr.push(now.getFullYear());
    dateStr.push("-");

    var month = now.getMonth()+1;

    if(month<10){
      dateStr.push("0");
    }

    dateStr.push(month);
    dateStr.push("-");

    var day = now.getDate();

    if(day<10){
      dateStr.push("0");
    }

    dateStr.push(day);

    return {
      queryModel: {
        userId: "",
        pointcut: "",
        data: "",
        remark: "",
        startTime: dateStr.join("") + " 00:00",
        endTime: dateStr.join("") + " 23:59"
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: []
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      console.log(pageIndex);

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("userId", self.queryModel.userId);
      formData.append("pointcut", self.queryModel.pointcut);
      formData.append("remark", self.queryModel.remark);
      formData.append("startTime", self.queryModel.startTime);
      formData.append("endTime", self.queryModel.endTime);


      self.loading = true;

      logApi.pageList(formData).then(function(response) {
        self.loading = false;

        var jsonData = response.data;

        if(jsonData.result){
          var pageInfo = jsonData.data;

          self.tableData = pageInfo.data;
          self.totalPages = pageInfo.totalPages;
          self.totalElements = pageInfo.recordsTotal;
        }
        else {
          self.$message({
              message: jsonData.message + "",
              type: "warning"
            });
        }
      }).catch((error)=>{
        self.loading = false;
      });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.changePage(1);
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    }
  },
  mounted: function() {
    var self = this;
    this.changePage(1);
  }
};
</script>
<style lang="scss" scoped>
  .el-breadcrumb {
    margin: 10px;
    line-height: 20px;
  }

  .el-divider {
    margin: 5px 0;
  }

  .demo-form-inline {
    margin-left: 10px;
    text-align: left;
  }

  .button-group {
    padding: 10px;
    text-align: left;
  }
</style>