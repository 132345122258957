import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
    return request.post(constant.serverUrl + "/sys/log/pageList", formData);
}


export default {
    pageList
}